var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.dataDetail.id)?_c('div',{staticClass:"manage-info"},[_c('div',{staticClass:"manage-info-item"},[_vm._m(0),_c('div',{staticClass:"cont"},[_c('div',{staticClass:"values"},[_vm._v(" "+_vm._s(_vm.dataDetail.firstCategory.name)+" ")])])]),_c('div',{staticClass:"manage-info-item"},[_vm._m(1),_c('div',{staticClass:"cont"},[_c('div',{staticClass:"values"},[_vm._v(" "+_vm._s(_vm.dataDetail.secondCategory.name)+" ")])])]),_c('div',{staticClass:"manage-info-item"},[_vm._m(2),_c('div',{staticClass:"cont"},[_c('div',{staticClass:"values"},[_vm._v(" "+_vm._s(_vm.dataDetail.category.name)+" ")])])]),_c('div',{staticClass:"manage-info-item"},[_vm._m(3),_c('div',[_vm._v(" "+_vm._s(_vm.dataDetail.name)+" ")])]),_c('div',{staticClass:"manage-info-item"},[_vm._m(4),_c('div',[_vm._v(_vm._s(_vm.dataDetail.map.name))])]),_c('div',{staticClass:"manage-info-item"},[_vm._m(5),_c('div',[_vm._v(_vm._s(_vm.dataDetail.data_count))])]),_c('div',{staticClass:"manage-info-item"},[_vm._m(6),_c('div',[_vm._v(_vm._s(_vm.dataDetail.total_score))])]),_c('div',{staticClass:"manage-info-item"},[_vm._m(7),_c('div',[_vm._v(" "+_vm._s(_vm.dataDetail.timelong)+"分钟 ")])]),_c('div',{staticClass:"manage-info-item"},[_vm._m(8),_c('div',[_vm._v(" "+_vm._s(_vm.dataDetail.join_max_times)+"次 ")])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"manage-info-item-name"},[_c('span'),_c('span',[_vm._v("一级分类:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"manage-info-item-name"},[_c('span'),_c('span',[_vm._v("二级分类:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"manage-info-item-name"},[_c('span'),_c('span',[_vm._v("三级分类:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"manage-info-item-name"},[_c('span'),_c('span',[_vm._v("岗位名称:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"manage-info-item-name"},[_c('span'),_c('span',[_vm._v("关联知识图谱:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"manage-info-item-name"},[_c('span'),_c('span',[_vm._v("总题数:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"manage-info-item-name"},[_c('span'),_c('span',[_vm._v("总分数:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"manage-info-item-name"},[_c('span'),_c('span',[_vm._v("总时长:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"manage-info-item-name"},[_c('span'),_c('span',[_vm._v("最多参与次数:")])])
}]

export { render, staticRenderFns }