<template>
  <div class="user-detail">
    <div style="margin:20px 0 0 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="layout-content" v-if="dataDetail.id" >
      <Row>
        <i-col span="2">
          <div class="head-img">
            <img v-if="dataDetail.avatar" :src="dataDetail.avatar">
            <p v-else-if="dataDetail.name">{{dataDetail.name && dataDetail.name[0].toUpperCase()}}</p>
          </div>
        </i-col>
        <i-col span="22">
          <Row>
            <i-col span="24" class="project-detail-info">
              <div class="info-item" >
                <strong style="font-size:16px; max-width: 600px; display: inline-block;padding-left: 10px;">{{ dataDetail.nickname }}</strong>
                <!--                <Button type="primary" @click="showEditSite">编辑</Button>-->
              </div>
            </i-col>
          </Row>
          <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
            <i-col span="4">ID: {{dataDetail.id}}</i-col>
            <i-col span="6">分类 : {{dataDetail.firstCategory.name}}-{{dataDetail.secondCategory.name}}-{{dataDetail.category.name}}</i-col>
            <i-col span="6">关联知识图谱 : {{dataDetail.map && dataDetail.map.name}}</i-col>
            <i-col span="4">数据量 : {{ dataDetail.data_count }}</i-col>
            <i-col span="4">总分数 : {{ dataDetail.total_score }}</i-col>
          </Row>
          <Row :gutter="20" style="font-size: 14px;margin: 10px 0;">
            <i-col span="4" >总时长 : {{ dataDetail.timelong }}分钟</i-col>
            <i-col span="6" >参与次数 : {{ dataDetail.join_max_times}}次</i-col>
            <i-col span="6" >创建时间 : {{ dataDetail.created_at }}</i-col>
            <i-col span="4" >更新时间 : {{ dataDetail.updated_at }}</i-col>
          </Row>
        </i-col>
      </Row>
    </div>
    <div class="tab-cont">
      <div class="tab-top">
        <div class="tab-top-l">
          <div :class="currentIndex == index ? 'active' : ''" v-for="(item,index) in tabList" :key="item.id" @click="changeTab(index)">{{item.name}}</div>
        </div>
      </div>
      <component :is="tabList[currentIndex].componentName" :dataDetail="dataDetail" :tree="mapDeatil.table_tree"></component>
    </div>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
import ManageInfo from './components/detail/manageInfo.vue'
import Chart from '../dataset/components/map/chart'
import RecordList from './components/detail/recordList.vue'
export default {
  name: "dataDetail",
  data(){
    return {
      dataDetail:{},
      dataId:'',
      tabList:[
        {
          name:'项目详情',
          id:1,
          componentName:'ManageInfo',
          tab:'info'
        },
        {
          name:'图谱',
          id:2,
          componentName:'Chart',
          tab:'chart'
        },
        {
          name:'参与记录',
          id:3,
          componentName:'RecordList',
          tab:'record'
        },
      ],
      currentIndex:0,
      mapDeatil:{},
    }
  },
  components:{
    ManageInfo,
    Chart,
    RecordList
  },
  created(){
    console.log("this.$route.params.id")
    console.log(this.$route.params.id)
    if (this.$route.params.id){
      this.dataId = this.$route.params.id
    }
    if (this.$route.params.tab){
      this.currentIndex = this.tabList.findIndex((item)=>{
        return item.tab == this.$route.params.tab;
      })
    }

    this.getDetail();
  },
  methods:{
    getDetail(){
      let params = {

      };
      if (this.dataId){
        params.id = this.dataId
      }

      this.api.dataset.datasetTrainEvaluationDetail(params).then((res)=>{
        this.dataDetail = res.info;
        this.dataDetail.created_at = util.timeFormatter(new Date(+res.info.created_at*1000), 'yyyy-MM-dd hh:mm');
        this.dataDetail.updated_at = util.timeFormatter(new Date(+res.info.updated_at*1000), 'yyyy-MM-dd hh:mm');

        this.getMapDetail();
      })
    },
    changeTab(data){
      // this.currentIndex = data;
      if(this.$route.params.tab == this.tabList[data].tab){
        return;
      }
      this.currentIndex = data;

      let option = {
        name:this.$route.name,
        params:{
          id: this.dataId,
          tab: this.tabList[this.currentIndex].tab
        }
      }
      this.$router.push(option)
    },
    getMapDetail(){
      let params = {
        id:this.dataDetail.map_id,
        site_id:-1,
      };
      this.loading = true;
      this.api.dataset.mapDetail(params).then((res)=>{
        this.loading = false;
        this.mapDeatil = res.info;

        // this.examDeatil.batch.name = util.decrypt(this.examDeatil.batch.name);
      }).catch((err)=>{
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped lang="scss">
.user-detail{
  .layout-content{
    margin: 20px;
    padding: 20px;
    background: #ffffff;
    min-height: 100px;
    border-radius: 4px;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);

    .head-img{
      width: 80px;
      height: 80px;
      border-radius: 80px;
      line-height: 80px;
      background-color: #ccc;
      text-align: center;
      font-size: 55px;
      color:#FFFFFF;
      >img{
        width: 100%;
        height: 100%;
        border-radius: 80px;
      }
    }

    .project-detail-info .info-item{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .project-detail-info .info-item button {
      margin-bottom: 5px;
    }
    .project-detail-info .info-item button + button {
      margin-left: 5px;
    }
    .info-item{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .tit-tag{
        padding: 5px 10px;
        text-align: center;
        color:#FFFFFF;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
  .tab-cont{
    margin: 0 20px 20px 20px;
    padding: 20px;
    background: #ffffff;
    //min-height: 180px;
    .tab-top{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //border:1px solid #dcdee2;
      text-align: center;
      height: 46px;
      overflow: hidden;
      font-size: 14px;
      cursor: default;
      .tab-top-l{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border:1px solid #dcdee2;
        height: 100%;
        border-radius: 4px;
        >div{
          width: 150px;
          border-right:1px solid #dcdee2;
          cursor: pointer;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        >div:last-child{
          border-right:none;
        }
        >div.active{
          background-color: #2d8cf0;
          color:#FFFFFF;
        }
      }

    }

  }
}
</style>
