<template>
<div class="manage-info" v-if="dataDetail.id">
  <div class="manage-info-item">
    <div class="manage-info-item-name">
      <span></span>
      <span>一级分类:</span>
    </div>
    <div class="cont">
      <div class="values">
        {{ dataDetail.firstCategory.name }}
      </div>
    </div>
  </div>
  <div class="manage-info-item">
    <div class="manage-info-item-name">
      <span></span>
      <span>二级分类:</span>
    </div>
    <div class="cont">
      <div class="values" >
        {{ dataDetail.secondCategory.name }}
      </div>
    </div>
  </div>
  <div class="manage-info-item">
    <div class="manage-info-item-name">
      <span></span>
      <span>三级分类:</span>
    </div>
    <div class="cont">
      <div class="values" >
        {{ dataDetail.category.name }}
      </div>
    </div>
  </div>
  <div class="manage-info-item">
    <div class="manage-info-item-name">
      <span></span>
      <span>岗位名称:</span>
    </div>
    <div>
      {{ dataDetail.name }}
    </div>
  </div>
  <div class="manage-info-item">
    <div class="manage-info-item-name">
      <span></span>
      <span>关联知识图谱:</span>
    </div>
    <div>{{ dataDetail.map.name }}</div>
  </div>
  <div class="manage-info-item">
    <div class="manage-info-item-name">
      <span></span>
      <span>总题数:</span>
    </div>
    <div>{{ dataDetail.data_count }}</div>
  </div>
  <div class="manage-info-item">
    <div class="manage-info-item-name">
      <span></span>
      <span>总分数:</span>
    </div>
    <div>{{ dataDetail.total_score }}</div>
  </div>
  <div class="manage-info-item">
    <div class="manage-info-item-name">
      <span></span>
      <span>总时长:</span>
    </div>
    <div>
      {{ dataDetail.timelong }}分钟
    </div>
  </div>
  <div class="manage-info-item">
    <div class="manage-info-item-name">
      <span></span>
      <span>最多参与次数:</span>
    </div>
    <div>
      {{ dataDetail.join_max_times }}次
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "manegeInfo.vue",
  props:{
    dataDetail:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  }
}
</script>

<style scoped lang="scss">
.manage-info{
  .manage-info-item{
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    //align-items: center;
    .manage-info-item-cont{
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .manage-info-item-name{
      margin-right: 10px;
      width: 120px;
      height: 32px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      >span:nth-child(1){
        margin-top: 7px;
        margin-right: 5px;
        font-size: 20px;
      }
    }
    .cont{
      margin-left: 15px;
      flex: 1;
      display: flex;
      justify-content: space-between;

      .values{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-flow: wrap;
        transition: max-height 0.3s linear;
        overflow: hidden;

        >div{
          padding: 5px 10px;
          //margin: 0 10px 10px 5px;
          cursor: pointer;
        }
        >div.active{
          background-color: #2d8cf0;
          color:#FFFFFF;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
