<template>
<div class="record-list">
  <div class="top">
    <div></div>
    <Input v-model="keyword"
           :placeholder="$t('common_search_keyword')"
           clearable
           search
           :enter-button="true"
           @on-enter="changeKeyword"
           @on-search="changeKeyword"
           class="top-right"
    />
  </div>
  <Table border :columns="columns" :loading="loading" :data="data"></Table>
  <div class="page">
    <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "recordList.vue",
  data(){
    return {
      columns:[
        {
          title: 'ID',
          key:'id',
        },
        {
          title: '姓名',
          key:'id',
          render:(h,params)=>{
            return h('span',params.row.user.nickname + '(id:'+params.row.user.id+')')
          }
        },
        // {
        //   title: '进度',
        //   key:'total_progress',
        // },
        {
          title: '得分',
          key:'total_score',
        },
        // {
        //   title: '正确率',
        //   key:'id',
        // },
        {
          title: '状态',
          key:'id',
          render:(h,params)=>{
            return h('span',params.row.trainUserList.length ? this.statusesObj[params.row.trainUserList[0].status] : '')
          }
        },
        {
          title: '创建时间',
          key:'id',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.created_at * 1000),
                    'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: '更新时间',
          key:'id',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.trainUserList[0].updated_at * 1000),
                    'yyyy-MM-dd hh:mm'
                )
            );
          }
        },{
          title: '操作',
          width:250,
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.checkChart();
                  }
                }
              }, '查看图谱'),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.checkReport();
                  }
                }
              }, '查看报告'),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.checkResult();
                  }
                }
              }, '查看结果')
            ]);
          }
        },

      ],
      data:[],
      loading:false,
      curData:{},
      total:0,
      page:1,
      pageSize:10,
      keyword:'',
      statusesObj:{},
      mapTableTree:[],
    }
  },
  props:{
    dataDetail:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  watch:{
    dataDetail:{
      handler(newV){
        if(newV.id){
          this.getList();
        }
      },
      immediate:true,
    }
  },
  created(){

  },
  methods:{
    getList(){
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword:this.keyword,
        evaluation_id:this.dataDetail.id,
      };
      this.loading = true;
      this.api.dataset.datasetTrainEvaluationTrainList(params).then((res)=>{
        this.loading = false;
        this.data = res.list;
        this.statusesObj = res.trainUserStatuses;
        this.total = Number(res.count);
      }).catch((err)=>{
        this.loading = false;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    changeKeyword(){
      this.page = 1;
      this.getList();
    },
    checkChart(){
      this.$router.push({
        path:'/dataset/check-chart',
        query:{
          evaluation_id:this.dataDetail.id,
          train_id:this.curData.id
        }
      })


    },
    checkReport(){
      this.$router.push({
        path:'/evaluation/report',
        query:{
          trainId:this.curData.id,
          id:this.curData.trainUserList.length ? this.curData.trainUserList[0].id : 0,
          isSuper:true,
        }
      })
    },
    checkResult(){
      let query = {
        id:this.curData.id,
        isResult:true,
        only_wrong:0,
        isEvaluationReport:true,
        site_id:-1,
        user_id:-1,
        trainUserId:this.curData.trainUserList.length ? this.curData.trainUserList[0].id : 0,
      }
      this.$router.push({
        path:'/trainingcamp/exam',
        query:query
      })
    }
  }
}
</script>

<style scoped lang="scss">
.top{
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .top-right{
    width: 200px;
  }
}
.page{
  margin-top: 20px;
  text-align: right;
}
</style>
